<template>
  <div id="homepage">
    <!-- ======= Header ======= -->
    <header
      id="header"
      class="fixed-top d-flex align-items-center header-transparent"
    >
      <site-header />
    </header>
    <!-- End Header -->

    <!-- ======= Hero Section ======= -->
    <Carousel
      :titlePage="'Danh sách đơn vị'"
    />
    <!-- End Hero -->

    <main id="main">
      <section
        class="team"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="500"
      >
        <div class="container">
          <div class="d-flex">
            <feather-icon
              icon="HomeIcon"
              size="20"
              color="#0282CD"
            />
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="ml-1"
            />
            <div class="titlePage">
              Danh sách đơn vị
            </div>
          </div>
          <b-row class="mt-2">
            <b-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              class="mt-1"
            >
              <search
                class="search-input"
                @change="handleSearch"
              />
            </b-col>
            <b-col
              xl="3"
              lg="3"
              md="3"
              sm="12"
              xs="12"
              class="mt-1"
            >
              <v-select
                :options="bussinessTypes"
                label="name"
                placeholder="Loại hình doanh nghiệp"
                class="sort"
                @input="(val) => {
                  urlQuery.bussinessClass = val ? val.id : ''
                  fetchBussinessData(urlQuery)
                }"
              />
            </b-col>
            <b-col
              xl="3"
              lg="3"
              md="3"
              sm="12"
              xs="12"
              class="mt-1"
            >
              <v-select
                :options="sorts"
                label="name"
                placeholder="Sắp xếp"
                class="sort"
                @input="(val) => {
                  urlQuery.sort = val ? val.type : ''
                  fetchBussinessData(urlQuery)
                }"
              />
            </b-col>
            <b-col
              xl="3"
              lg="3"
              md="3"
              sm="12"
              xs="12"
              class="mt-1"
            >
              <v-select
                v-model="urlQuery.provinceId"
                :options="dataProvinces"
                label="name"
                :reduce="label => label.id"
                placeholder="Tỉnh/Thành phố"
                class="sort"
                @input="filterProvinces"
              />
            </b-col>
            <b-col
              xl="3"
              lg="3"
              md="3"
              sm="12"
              xs="12"
              class="mt-1"
            >
              <v-select
                v-model="urlQuery.districtId"
                :options="dataDistricts"
                :reduce="label => label.id"
                label="name"
                placeholder="Quân/Huyện"
                class="sort"
                @input="filterDistricts"
              />
            </b-col>
            <b-col
              xl="3"
              lg="3"
              md="3"
              sm="12"
              xs="12"
              class="mt-1"
            >
              <v-select
                v-model="urlQuery.wardId"
                :options="dataWards"
                :reduce="label => label.id"
                label="name"
                placeholder="Xã/Phường"
                class="sort"
                @input="filterWards"
              />
            </b-col>
          </b-row>
          <div class="row box-text">
            <div
              v-for="item in dataBussinessList"
              :key="item.id"
              class="col-lg-4 col-md-6 d-flex align-items-stretch justify-content-center"
            >
              <div class="member">
                <div class="member-img">
                  <a @click="showDetail(item.id)">
                    <img
                      :src="item.avatar ? ($serverfile + '/' + item.avatar) : 'assets/img/enterprise/img_02.png'"
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                  <div class="social">
                    <a href=""><i class="bi bi-twitter"></i></a>
                    <a href=""><i class="bi bi-facebook"></i></a>
                    <a href=""><i class="bi bi-instagram"></i></a>
                    <a href=""><i class="bi bi-linkedin"></i></a>
                  </div>
                </div>
                <div class="member-info">
                  <a
                    @click="showDetail(item.id)"
                  >
                    <h4>{{ item.name }}</h4>
                  </a>
                  <span>{{ item.bussinessType }}</span>
                  <div class="row">
                    <div class="col-1">
                      <i class="bi bi-geo-alt"></i>
                    </div>
                    <div class="col-11">
                      {{ item.address }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-1">
                      <i class="bi bi-telephone"></i>
                    </div>
                    <div class="col-11">
                      {{ item.phoneNumber }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="demo-spacing-0 justify-content-center d-flex">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPages"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </section>
      <!-- End Team Section -->
    </main>
    <!-- End #main -->

    <!-- ======= Footer ======= -->
    <footer
      id="footer"
      data-aos="fade-up"
      data-aos-easing="ease-in-out"
      data-aos-duration="500"
    >
      <site-footer />
    </footer>
  </div>
</template>
<script src="./assets/js/main.js"></script>
<script>
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import "./assets/vendor/aos/aos.js";
import ConstantsApi from './constants/ConstantsApi'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import Search from '@/components/search/Search.vue'
import vSelect from 'vue-select'
import Carousel from './components/Carousel.vue'
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'
import {
  BInputGroup,
  BPagination,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
} from "bootstrap-vue";

export default {
  components: {
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BPagination,
    Search,
    SiteHeader,
    SiteFooter,
    vSelect,
    Carousel,
    BRow,
    BCol,
  },
  data() {
    return {
      tokenCheck: localStorage.getItem("token"),
      totalPages: 0,
      currentPage: 1,
      urlQuery: {
        pageSize: 9,
        pageNumber: 1,
        key: '',
        provinceId: null,
        districtId: null,
        wardId: null,
      },
      dataBussinessList: [],
      sorts: [
        {name: 'Tăng dần A-Z', type: 'name+'},
        {name: 'Giảm dần Z-A', type: 'name-'}
      ],
      dataProvinces: [],
      dataDistricts: [],
      dataWards: [],
      bussinessTypes: [
        {
          id: 'Bussiness',
          name: 'Doanh nghiệp',
        },
        {
          id: 'Accreditation',
          name: 'Tổ chức kiểm định',
        },
        {
          id: 'Training',
          name: 'Tổ chức huấn luyện',
        },
        {
          id: 'Health',
          name: 'Trung tâm y tế',
        },
        {
          id: 'JobCenter',
          name: 'Trung tâm giới thiệu việc làm',
        },
      ]
    };
  },
  mounted() {
    /* eslint-disable no-undef */
    /* eslint-disable no-new */
    // eslint-disable-next-line func-names
    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      }
      return document.querySelector(el);
    };

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      const selectEl = select(el, all);
      if (selectEl) {
        if (all) {
          selectEl.forEach((e) => e.addEventListener(type, listener));
        } else {
          selectEl.addEventListener(type, listener);
        }
      }
    };

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
      el.addEventListener("scroll", listener);
    };

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
      const header = select("#header");
      let offset = header.offsetHeight;

      if (!header.classList.contains("header-scrolled")) {
        offset -= 20;
      }

      const elementPos = select(el).offsetTop;
      window.scrollTo({
        top: elementPos - offset,
        behavior: "smooth",
      });
    };

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    const selectHeader = select("#header");
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add("header-scrolled");
        } else {
          selectHeader.classList.remove("header-scrolled");
        }
      };
      window.addEventListener("load", headerScrolled);
      onscroll(document, headerScrolled);
    }

    /**
     * Back to top button
     */
    const backtotop = select(".back-to-top");
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add("active");
        } else {
          backtotop.classList.remove("active");
        }
      };
      window.addEventListener("load", toggleBacktotop);
      onscroll(document, toggleBacktotop);
    }

    /**
     * Mobile nav toggle
     */
    // eslint-disable-next-line func-names
    on("click", ".mobile-nav-toggle", function (e) {
      select("#navbar").classList.toggle("navbar-mobile");
      this.classList.toggle("bi-list");
      this.classList.toggle("bi-x");
    });

    /**
     * Mobile nav dropdowns activate
     */
    // eslint-disable-next-line func-names
    on(
      "click",
      ".navbar .dropdown > a",
      function (e) {
        if (select("#navbar").classList.contains("navbar-mobile")) {
          e.preventDefault();
          this.nextElementSibling.classList.toggle("dropdown-active");
        }
      },
      true
    );

    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    // eslint-disable-next-line func-names
    on(
      "click",
      ".scrollto",
      function (e) {
        if (select(this.hash)) {
          e.preventDefault();

          const navbar = select("#navbar");
          if (navbar.classList.contains("navbar-mobile")) {
            navbar.classList.remove("navbar-mobile");
            const navbarToggle = select(".mobile-nav-toggle");
            navbarToggle.classList.toggle("bi-list");
            navbarToggle.classList.toggle("bi-x");
          }
          scrollto(this.hash);
        }
      },
      true
    );

    /**
     * Skills animation
     */
    const skilsContent = select(".skills-content");
    if (skilsContent) {
      // eslint-disable-next-line no-new
      new Waypoint({
        element: skilsContent,
        offset: "80%",
        handler(direction) {
          const progress = select(".progress .progress-bar", true);
          progress.forEach((el) => {
            el.style.width = `${el.getAttribute("aria-valuenow")}%`;
          });
        },
      });
    }

    /**
     * Testimonials slider
     */
    // eslint-disable-next-line no-new
    new Swiper(".testimonials-carousel", {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    });

    /**
     * Porfolio isotope and filter
     */
    window.addEventListener("load", () => {
      const portfolioContainer = select(".portfolio-container");
      if (portfolioContainer) {
        // eslint-disable-next-line no-undef
        const portfolioIsotope = new Isotope(portfolioContainer, {
          itemSelector: ".portfolio-wrap",
          layoutMode: "fitRows",
        });

        const portfolioFilters = select("#portfolio-flters li", true);

        // eslint-disable-next-line func-names
        on(
          "click",
          "#portfolio-flters li",
          function (e) {
            e.preventDefault();
            portfolioFilters.forEach((el) => {
              el.classList.remove("filter-active");
            });
            this.classList.add("filter-active");

            portfolioIsotope.arrange({
              filter: this.getAttribute("data-filter"),
            });
            portfolioIsotope.on("arrangeComplete", () => {
              // eslint-disable-next-line no-undef
              AOS.refresh();
            });
          },
          true
        );
      }
    });

    /**
     * Initiate portfolio lightbox
     */
    // eslint-disable-next-line no-undef
    const portfolioLightbox = GLightbox({
      selector: ".portfolio-lightbox",
    });

    /**
     * Portfolio details slider
     */
    // eslint-disable-next-line no-new
    // eslint-disable-next-line no-undef
    new Swiper(".portfolio-details-slider", {
      speed: 400,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    });

    /**
     * Animation on scroll
     */
    window.addEventListener("load", () => {
      // eslint-disable-next-line no-undef
      AOS.init({
        duration: 1000,
        easing: "ease-in-out",
        once: true,
        mirror: false,
      });
    });
  },
  created() {
    if (this.$route.params.check === true) {
      this.reload();
    }

    this.fetchBussinessData(this.urlQuery)
    this.getListProvinces()
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      this.$ability.update(initialAbility);
      this.$router.push({ name: "home" });
      this.$router.go();
    },

    async getListProvinces() {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_PROVINCES)
      this.dataProvinces = data
    },
    async getListDistricts(id) {
      if (id) {
        const { data } = await axiosApiInstance.get(`${ConstantsApi.FECTH_DISTRICTS}${id}`)
        this.dataDistricts = data
      }
    },
    async getListWards(id) {
      if (id) {
        const { data } = await axiosApiInstance.get(`${ConstantsApi.FECTH_WARDS}${id}`)
        this.dataWards = data
      }
    },

    filterProvinces(val) {
      this.dataDistricts = []
      this.getListDistricts(val)
      this.urlQuery.provinceId = val
      this.urlQuery.pageNumber = 1
      this.urlQuery.districtId = null
      this.urlQuery.wardId = null
      this.fetchBussinessData(this.urlQuery)
    },
    filterDistricts(val) {
      this.dataWards = []
      this.getListWards(val)
      this.urlQuery.pageNumber = 1
      this.urlQuery.wardId = null
      this.fetchBussinessData(this.urlQuery)
    },
    filterWards() {
      this.urlQuery.pageNumber = 1
      this.fetchBussinessData(this.urlQuery)
    },

    async fetchBussinessData(urlQuery) {
      const { data } = await axiosApiInstance.get(ConstantsApi.BUSSINESS_GET_PAGING, {
        params: urlQuery,
      })
      this.dataBussinessList = data?.data?.pageLists
      this.totalPages = data?.data?.totalRecord
    },
    reload() {
      setTimeout(this.$router.go(), 4000);
    },

    showDetail(id){
      this.$router.push({ name: "enterprise-detail-home",params: {id} })
    },

    handleSearch(val) {
      if (val) {
        this.urlQuery.key = val
        this.urlQuery.pageSize = 9
        this.urlQuery.pageNumber = 1
        this.fetchBussinessData(this.urlQuery)
      } else {
        this.urlQuery.key = ''
        this.urlQuery.pageSize = 9
        this.urlQuery.pageNumber = 1
        this.fetchBussinessData(this.urlQuery)
      }
    }
  },
};
</script>
<style lang="scss">
.member-img {
  height:  200px;
  width: 350px;
}
.sort {
  width: 100%;
  margin-right: 1rem;
}

.member {
  width: min-content;
}

#main {
  .container {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
